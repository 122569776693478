.container {
  display: grid;
  width: var(--container-width);
  max-width: var(--container-max-width);
  margin: 84px auto;
  padding: 0 var(--space-6x);
  gap: var(--space-10x);
  flex-wrap: wrap;

  @media (--screen-lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--screen-2xl) {
    grid-template-columns: repeat(3, 1fr);
  }
}
