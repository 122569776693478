.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0 4px 8px -2px rgba(0 0 0 / 10%);
  padding: var(--space-6x);
  border-radius: var(--border-radius-6x);
  justify-content: space-between;
  gap: var(--space-6x);
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading,
.description {
  text-align: center;
}

.heading {
  margin-bottom: var(--space-3x);
  color: var(--color-black);
  font-size: var(--font-size-xl);
  line-height: normal;
  max-width: 201px;

  @media (--screen-lg) {
    max-width: 196px;
  }
}

.description {
  font-size: var(--font-size-body);
  color: var(--color-gray-500);
  line-height: 20px;
}

.buttons {
  --icon-button-flex: 1;
  display: flex;
  justify-content: center;
  gap: var(--space-2x);
  width: 100%;
}

.button {
  font-size: 15px;
  border-radius: var(--border-radius-6x);
  padding: var(--space-2-5x) var(--space-6x);
}

.appButton {
  --button-text-color-base: var(--color-white);
  --button-text-color-hover: var(--color-white);
  --button-gap: var(--space-1x);
  background-color: var(--color-black);
  padding: var(--space-2x) 0;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
}

.appleIcon {
  font-size: 22px;
}

.image {
  width: 100%;
  height: auto;
}
