.threeDTourButtonRedesign {
  background: var(--color-white);
  border-radius: 20px;
  font-size: var(--font-size-sm);
  padding: var(--space-1x) var(--space-2-5x);
  z-index: var(--z-index-2x);

  &:hover {
    background: var(--color-gray-100);
  }
}

.threeDTourButtonRedesignVersionTwo {
  color: var(--color-black);
  background: var(--color-white);
  border-radius: var(--border-radius-4x);
  font-size: 13px;
  padding: var(--space-2x) var(--space-2-5x);
  z-index: var(--z-index-2x);
  line-height: var(--leading-tight);
  font-weight: var(--font-weight-medium);
  transition: all 100ms cubic-bezier(0, 0, 0.2, 1);
  transition-property: color, background-color;

  @media (--supports-hover) {
    &:hover {
      background-color: var(--color-gray-200);
      color: var(--color-black);
    }

    &:active {
      background-color: var(--color-gray-300);
      color: var(--color-black);
    }
  }
}
