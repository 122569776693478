@layer components {
  .badge {
    background-color: var(--color-white);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    border-radius: var(--border-radius-2x);
    display: flex;
    align-items: center;
    color: var(--color-gray-500);
  }

  .dealsText {
    display: inline-block;
    padding-left: var(--space-1x);
    padding-right: var(--space-1x);
    border-radius: var(--border-radius-1x);
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    margin: 2px 0 2px 2px;
    color: var(--color-white);
    background-color: var(--badge-background-color);
  }

  .promotionText {
    padding-left: var(--space-1x);
    padding-right: var(--space-1x);
    text-transform: capitalize;
  }

  .iconBadge {
    margin: var(--space-1x) 0 var(--space-1x) var(--space-1x);
    font-size: var(--font-size-md);
    color: var(--badge-icon-color);
  }
}
