.seoLinksSection {
  max-width: 1325px;
  --heading-font-size: var(--font-size-2xl);
  --container-padding: 0;
  padding: 0 var(--space-6x);
  margin: 84px auto;

  @media (--screen-md) {
    margin: var(--space-24x) auto var(--space-14x);
  }

  @media (--screen-lg) {
    margin: var(--space-14x) auto;
  }
}

.heading {
  margin-bottom: var(--space-4x);
}

.seoLinks {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: var(--space-8x);
  border-bottom: 1px solid var(--color-gray-300);

  @media (--screen-md) {
    padding-bottom: var(--space-14x);
    flex-direction: row;
  }
}
