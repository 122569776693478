.wrapper {
  position: relative;
  z-index: var(--z-index-1x);
  width: 100%;
  height: 345px;
  white-space: pre-line;
  --home-page-search-results-max-height: 55vh;
  --search-combobox-search-button-font-size: var(--font-size-lg);

  @media (--screen-md) {
    --search-combobox-search-button-font-size: var(--font-size-lg);
  }
}

.imageWrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageWrapper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(0 0 0 / 40%) 0%, #000 100%);
  opacity: 0.5;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: var(--home-page-form-max-width, 594px);
  text-align: center;
  margin: 0 auto;
  padding: var(--space-14x) 30px 0;

  @media (--screen-md) {
    padding: var(--space-14x) 0 0 0;
  }
}

.header {
  color: var(--color-white);
  letter-spacing: -0.03em;
  text-shadow: 0 2px 2px rgb(0 0 0 / 35%);
  font-size: var(--font-size-3xl);
  line-height: normal;
  margin-bottom: 23px;
  max-width: 330px;

  @media (--screen-sm) {
    font-size: var(--font-size-4xl);
  }

  @media (--screen-md) {
    max-width: none;
    font-size: var(--font-size-6xl);
    line-height: 54px;
  }
}

.imageCloudinary {
  object-fit: cover;
  width: 2500px;
}

.imageDefault {
  height: 100%;
  object-fit: cover;
}

.form {
  margin: 0 auto;
  width: var(--home-page-form-width, 100%);
  font-size: var(--font-size-lg);

  .searchInput {
    height: 72px;
  }

  .searchResults {
    --search-combobox-list-wrap-left: 0;
    position: absolute;
    transform: translateY(var(--space-2x));
    top: 72px;

    @media (--screen-md) {
      max-height: var(--home-page-search-results-max-height);
    }
  }

  .searchButton {
    padding: var(--space-2-5x) var(--space-6x);
  }

  .searchResultHeader:first-child {
    border: 0;
  }
}

.searchComboBox {
  --search-combobox-button-border-radius: 1111px;
  --search-combobox-root-border-radius: 1111px;
  --search-combobox-root-padding: 0 0 0 var(--space-3x);
  --search-combobox-header-border-radius: var(--border-radius-full);
  border-radius: var(--border-radius-full);
}
