@layer components {
  .iconCtaStyle {
    border-radius: var(--icon-cta-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--icon-cta-margin);
    padding: var(--icon-cta-padding);
    position: var(--icon-cta-position);
    background-color: var(--icon-cta-background-color);
    font-size: var(--icon-cta-font-size);
    width: var(--icon-cta-width);
    height: var(--icon-cta-height);
    z-index: var(--icon-cta-z-index);
    top: var(--icon-cta-top);
    right: var(--icon-cta-right);
    box-shadow: var(--icon-cta-box-shadow);
  }

  .buttonContainer {
    display: flex;
  }

  .shareButtonAG,
  .favoriteButtonAG {
    font-size: var(--font-size-2xl);
    height: var(--font-size-2xl);
    width: var(--font-size-2xl);
  }

  .shareButtonRedesignV2 {
    --button-font-size: var(--font-size-lg);
    --button-height: 34px;
    --button-width: 38px;
    --button-padding: 0;
  }

  .withRentStyles {
    --button-height: 26px;
    --button-width: var(--space-10x);
    --button-padding: var(--space-1-5x) var(--space-2x);
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: 0;
      right: 0;
    }
  }

  .savedText {
    font-size: var(--font-size-sm);
  }

  @keyframes pulse-heart {
    0% {
      transform: scale(1.1);
    }
    20% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .bookmarkLabel {
    line-height: 1;
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-semibold);
  }

  .withRentStyles .bookmarkLabel {
    font-size: var(--font-size-sm);
  }

  .favoriteButtonRedesignV2 {
    --button-font-size: var(--font-size-lg);
    --button-height: 34px;
    --button-width: 38px;
    --button-padding: 0;

    .filledHeart {
      color: var(--button-background-color-base);
    }
    .clickAnimation {
      animation: 400ms linear 0s 1 pulse-heart;
    }
  }

  .shareButtonRedesignV1 {
    font-size: var(--font-size-lg);
  }

  .favoriteButton {
    display: flex;
    --icon-cta-filled-color: var(--bookmark-filled-background-color);
  }

  .favoriteButton.withRentStyles {
    --button-width: 70px;
    --button-gap: 4px;

    .filledHeart {
      color: var(--color-pink-400);
    }
  }

  .default {
    color: var(--icon-cta-base-color);

    &:hover {
      color: var(--icon-cta-hover-color);
      background-color: var(--icon-cta-hover-background-color);
    }

    &:active {
      color: var(--icon-cta-active-color);
      background-color: var(--icon-cta-active-background-color);
    }
  }

  .filled {
    color: var(--icon-cta-filled-color);
  }
}
